<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialog" width="100vh" max-width="800" scrollable persistent>
			<v-card>
				<v-card-title>Alterar código de barras</v-card-title>
				<v-divider />
				<v-card-text class="black--text pt-5">
					<v-text-field
						v-model.number="produto.idsubproduto"
						type="number"
						outlined
						dense
						label="Cód. Produto"
						autofocus
						@change="get()"
					></v-text-field>
					<v-text-field :value="produto.descricao" disabled outlined dense label="Descrição"></v-text-field>
					<v-text-field
						v-model.number="produto.codbarra"
						type="number"
						outlined
						dense
						label="Cód. Barras"
						class="mb-n5"
            :disabled="erro != null"
					></v-text-field>
          <v-alert class="my-2 mb-n2 error white--text" v-if="erro">
            <p class="ma-0 text-justify">{{erro.mensagem}}. Se prosseguir com a alteração, o código de barras{{erro.numcaixa == 2 ?' do produto' : ' da caixa'}} será <strong>"zerado"</strong></p>
          </v-alert>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3 pr-6">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialog = false"
					>Cancelar</v-btn>
					<v-btn v-if="!erro" :loading="carregando" color="primary" elevation="0" @click="alterar()">Alterar</v-btn>
          <v-btn v-if="erro" :loading="carregando" color="error" elevation="0" @click="alterarExistente()">Alterar mesmo assim</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					Cadastro de medidas
					<v-spacer />
					<v-btn elevation="0" color="primary" @click="cadastrar()">Cadastrar</v-btn>
					<v-btn elevation="0" color="error" @click="dialog = true, erro = null" class="ml-2">Cód. Barras</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-row>
						<v-col cols="12" md="3">
							<v-text-field
								v-model.number="produto.codbarra"
								type="number"
								outlined
								dense
								label="Cód. Barras"
								autofocus
								class="mb-n5"
								@change="get()"
							></v-text-field>
						</v-col>
						<v-col v-if="produto.idsubproduto" cols="12" md="9" class="text-center font-weight-bold">
							{{produto.idsubproduto}} - {{produto.descricao}}
							<br />
							{{produto.subdescricao}}
						</v-col>
						<v-col v-else cols="12" md="9" class="text-center font-weight-bold"></v-col>
						<v-col cols="12" md="3">
							<v-text-field
								v-model.number="produto.peso"
								type="number"
								outlined
								dense
								label="Peso (KG)"
								class="mb-n5"
								@keydown.enter="cadastrar()"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								v-model.number="produto.altura"
								type="number"
								outlined
								dense
								:suffix="`${(produto.altura || 0) / 10} CM`"
								label="Altura"
								class="mb-n5"
								@keydown.enter="cadastrar()"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								v-model.number="produto.largura"
								type="number"
								outlined
								dense
								:suffix="`${(produto.largura || 0) / 10} CM`"
								label="Largura"
								class="mb-n5"
								@keydown.enter="cadastrar()"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								v-model.number="produto.comprimento"
								type="number"
								outlined
								dense
								:suffix="`${(produto.comprimento || 0) / 10} CM`"
								label="Comprimento"
								class="mb-n5"
								@keydown.enter="cadastrar()"
								@change="cadastrar()"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-title>
					<v-row>
						<v-col cols="auto">Produtos Alterados</v-col>
						<v-col>
							<v-text-field
								class="mb-n7"
								:disabled="carregando"
								v-model="busca.busca"
								label="Buscar"
								outlined
								dense
								@keydown.enter="listar(0)"
							/>
						</v-col>
						<v-col cols="auto" class="ml-n3">
							<v-btn
								:disabled="carregando"
								class="mt-1"
								color="primary"
								elevation="0"
								fab
								x-small
								@click="listar(0)"
							>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-divider />
				<v-card-text>
					<v-simple-table>
						<thead>
							<tr>
                <th nowrap>Id Alteração</th>
								<th>Produto</th>
								<th>Cód Barra</th>
								<th>Peso (KG)</th>
								<th>Altura (CM)</th>
								<th>Largura (CM)</th>
								<th>Comprimento (CM)</th>
								<th>Descrição</th>
								<th>SubDescrição</th>
								<th>Usuário</th>
								<th>Data Alteração</th>
							</tr>
						</thead>
						<tbody v-if="dados.total > 0">
							<tr v-for="(u,i) in dados.lista" :key="i">
                <td>{{u.id}}</td>
								<td>{{u.idsubproduto}}</td>
								<td>{{u.codbarra}}</td>
								<td class="text-center">{{u.peso}}</td>
								<td class="text-center">{{u.altura}}</td>
								<td class="text-center">{{u.largura}}</td>
								<td class="text-center">{{u.comprimento}}</td>
								<td>{{u.descricao}}</td>
								<td>{{u.subdescricao}}</td>
								<td class="text-center">{{u.idusuarioalteracao}}</td>
								<td>{{u.dtalteracao}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="10" class="text-center">Nenhum registro encontrado</td>
							</tr>
						</tbody>
					</v-simple-table>
					<Paginacao
						:total="dados.total"
						@atualizar="listar"
						:carregando="carregando"
						:pg="pgSelecionada"
					/>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog.vue";
import Paginacao from "../Widgets/Paginacao.vue";
export default {
	components: { CardDialog, Paginacao },
	name: "ComprasCadastraProduto",
	data: () => ({
    erro : null,
		carregando: false,
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		busca: {},
		dados: { lista: [], total: 0 },
		produto: {},
	}),
	computed: {
		...mapState(["backendUrl", "usuario", "pgLimit"]),
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}compra/cadastro/produtos/listar`, {
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
					busca: this.busca.busca || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
				});
		},
		get() {
			if (!this.produto.codbarra && !this.produto.idsubproduto) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg = "Código identificador não informado!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
      this.erro = null;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}compra/cadastro/produtos/get`, {
					codbarra: this.produto.codbarra || null,
					idsubproduto: this.produto.idsubproduto || null,
				})
				.then((res) => {
          this.produto = {};
					this.produto = res.data;
					if (this.produto.atualizado) {
						this.dialogErro.title = "Alerta!";
						this.dialogErro.msg = "Esse produto já foi atualizado!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "orange";
						this.dialogErro.status = true;
					}
					this.carregando = false;
				})
				.catch(() => {
					this.produto.idsubproduto = 0;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Produto não encontrado!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
					this.carregando = false;
				});
		},
		cadastrar() {
			if (
				!this.produto.codbarra ||
				!this.produto.peso ||
				!this.produto.altura ||
				!this.produto.largura ||
				!this.produto.comprimento
			) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Um ou mais campos não foram preenchidos!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.produto.peso <= 0 || this.produto.peso > 500) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"O peso deve ser entre 0,01 kg e 500,00 kg!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.produto.altura <= 0 || this.produto.altura > 10000) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"A altura deve ser entre 0,1 cm e 10 metros!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.produto.largura <= 0 || this.produto.largura > 10000) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"A largura deve ser entre 0,1 cm e 5 metros!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (
				this.produto.comprimento <= 0 ||
				this.produto.comprimento > 10000
			) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"O comprimento deve ser entre 0,1 cm e 5 metros!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}compra/cadastro/produtos`, {
					...this.produto,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg =
							"Produto(s) alterado(s) com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.produto = {};
						this.listar(0);
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg = "Erro ao atualizar produto(s)!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Erro ao atualizar produto(s)!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
					this.carregando = false;
				});
		},
		alterar() {
			if (!this.produto.codbarra || !this.produto.idsubproduto) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Um ou mais campos não foram preenchidos!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}compra/cadastro/produtos/codbarra`, {
					...this.produto,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data.resposta) {
						this.dialog = false;
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg =
							"Produto(s) alterado(s) com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						this.produto = {};
						this.listar(0);
					} else {
            this.erro = res.data.item
          }
				})
				.catch((e) => {
          if (e.response.data.erro) {
            this.erro.mensagem = e.response.data.erro;
          }
					this.carregando = false;
				});
		},
    alterarExistente() {
      if (!this.produto.codbarra || !this.produto.idsubproduto) {
        this.dialogErro.title = "Erro";
        this.dialogErro.msg = "Um ou mais campos não foram preenchidos!";
        this.dialogErro.icon = "mdi-alert-circle-outline";
        this.dialogErro.iconColor = "red";
        this.dialogErro.status = true;
        return;
      }
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}compra/cadastro/produtos/codbarra/existente`, {
            ...this.produto,
          })
          .then((res) => {
            this.carregando = false;
            if (res.data) {
              this.dialog = false;
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Produto(s) alterado(s) com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.produto = {};
              this.listar(0);
            }
          })
          .catch((e) => {
            if (e.response.data.erro) {
              this.erro = e.response.data.erro;
            }
            this.carregando = false;
          });
    },
		init() {
			this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>